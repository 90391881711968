<template>
	<div class="row kt-pt25">
		<div class="col-12 col-sm-12">
			<div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
				<div class="kt-portlet__body svg_list_trainig">
					<div class="row kt-mb-25">
						<div class="col-12 col-sm-7">
							<div class="kt-input-icon kt-input-icon--left col-sm-5">
								<input
									type="text"
									v-model="search"
									class="form-control"
									:placeholder="l.app.Search"
								/>
								<span
									@click="searchData"
									class="kt-input-icon__icon kt-input-icon__icon--left"
									style="cursor: pointer;"
								>
									<span> <i class="material-icons">search</i> </span>
								</span>
							</div>
							
						</div>
						<div class="col-12 col-sm-5" style="text-align: right;">
							<select
								class="mr-2 py-3 px-2 appearance-none font-medium border outline-none border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
								@input="orderBy($event.target.value)">
								<option value="">-- {{ this.l.app.all}} --</option>
								<option value="lower:title,desc">{{ this.l.app.Title}},Desc</option>
								<option value="lower:title,asc">{{ this.l.app.Title}},Asc</option>
								<!--option value="created_at,desc">{{ this.l.app.created_at}},Desc</option>
								<option value="created_at,asc">{{ this.l.app.created_at}},Asc</option-->
								<option value="updated_at,desc">{{ this.l.app.updated_at}},Desc</option>
								<option value="updated_at,asc">{{ this.l.app.updated_at}},Asc</option>
							</select>
							

							<sim-button
								align="right"
								:to="{name: 'media.create'}">
								<i class="material-icons"> add</i>
								{{ this.l.app.Create}}
							</sim-button>
						</div>
					</div>
					
					<!-- aqui va la tabla -->
					<template v-if="loading.index == false">
						<div  class="row kt-mb-25">
							<template v-if="pagination && pagination.data && pagination.data.length">
								<div v-for="item in pagination.data" class="col-lg-3 col-md-4 col-sm-12" :key="item.id" style="padding: 10px;" >
									<div class="box_media_page" style="position: relative;">
										<div style="position: absolute;bottom: 10px;z-index: 8;right: 10px;font-size: 12px;">
											<button v-if="item.status === 'pending'" class="btn btn-label-primary" @click="publish(item.id)" title="Publish">
												<i class="material-icons">done_all</i>
											</button>
											<button class="btn btn-label-danger" @click="deleteM(item.id)" :title="l.app.Remove">
												<i class="material-icons"> delete</i>
											</button>
										</div>
										<router-link
											:to="`/media/${item.id}`"
											style="float: left; width: 100%;">
											<template v-if="['video','podcast'].includes(item.type) && item.url && item.url != ''">
												<video-embed v-if="isUrlEmbed(item.url)" css="embed-responsive-16by9" class="video-embed"  :src="item.url" v-show="true" ></video-embed>
												<template v-else-if="isUrlFacebook(item.url)">
													<iframe
														v-show="true"
														:src="`https://www.facebook.com/plugins/video.php?href=${item.url}`"
														width="370"
														height="200"
														style="border-radius: 10px 10px 0px 0px; overflow: hidden"
														scrolling="no"
														frameborder="0"
														allowTransparency="true"
														allowFullScreen="true">
													</iframe>
												</template>
												<div v-else class="col-12 figure-img" style="background: linear-gradient(97deg, #1b70b7 0%, #e51d8a 100%)">
													<span style="position: absolute;bottom: 10px;color: white;right: 10px;font-size: 18px;">{{item.type}} Releasse</span>
												</div>
											</template>
											
											<div v-else-if="item.featured_image_url && item.featured_image_url !== ''"
												class="col-12 figure-img"
												:style="`background-image: url(${item.featured_image_url})`">
											</div>
											<div v-else class="col-12 figure-img" style="background: linear-gradient(97deg, #1b70b7 0%, #e51d8a 100%)">
												<span style="position: absolute;bottom: 10px;color: white;right: 10px;font-size: 18px;">{{item.type}} Releasse</span>
											</div>
											<div class="col-12 footer" style="float: left;">
												<div class="col-12 title mb-0 px-0">
													{{ item.title }}
												</div>
												<div class="col-12 row">
													<template>
														<span
															v-if="item.company"
															class="tap col-12 px-0"
															style="font-weight: bold;text-transform: capitalize;"
															>{{ item.company.name }}</span
														>
														<span
															v-else-if="item.user_profile"
															class="tap col-12"
															style="font-weight: bold;text-transform: capitalize;"
															>{{ item.user_profile.name }}
															{{ item.user_profile.last_name }}</span
														>
													</template>

													<span
														class="date col-12 white-space-nowrap px-0 overflow-hidden"
														style="line-height: 24px; font-size: 12px;">
														Updated at
														{{moment(item.updated_at).format("MMMM DD, YYYY hh:mm a")}}
													</span>

													
												</div>

												<div class="col-12 row">
													<div class="col-9 px-0">
														<div
															v-if="item.views_count > 0"
															class="col-12"
															style="padding-left: 20px; position: relative;min-height: 24px;"
														>
															<span
																class="material-icons"
																style="line-height: 24px; font-size: 17px; position: absolute; left: 0px; top: 0px;"
																>visibility</span
															>
															<span
																class="white-space-nowrap"
																style="line-height: 24px; font-size: 12px;"
																>{{ item.views_count }} Views</span
															>
														</div>
														<div
															v-else
															class="col-12"
															style="padding-left: 20px; position: relative;min-height: 24px;"
														>
														</div>

														<div
															v-if="item.allow_comments && item.comments_count > 0"
															class="col-12 px-0"
															style="padding-left: 20px; position: relative;min-height: 24px;"
														>
															<span
																class="material-icons"
																style="line-height: 24px; font-size: 17px; position: absolute; left: 0px; top: 0px;"
																>comment</span
															>
															<span
																class="white-space-nowrap"
																style="line-height: 24px; font-size: 12px;"
																>{{ item.comments_count }} Comments</span
															>
														</div>
														<div
															v-else
															class="col-12"
															style="padding-left: 20px; position: relative;min-height: 24px;"
														>
														</div>
													</div>
												</div>
												
											</div>
										</router-link>
									</div>
								</div>
							</template>

							<div v-else class="d-flex justify-content-center w-100 text-bold mt-3">
								<h4>{{this.l.app.no_results_found}}</h4>
							</div>
						</div>
						<template v-if="pagination && pagination.data && pagination.data.length">
						<sim-pagination
							align="right"
							:data="pagination"
							@change="changePage($event)"/>
						</template>
					</template>
					<p v-else class="text-2xl text-center pt-16 p-8 animate-bounce">{{l.app.loading}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState,mapGetters} from "vuex";
	import moment from "moment";

	export default {
		data: () => ({
			columns: [],
			limit: 10,
			o_data: {},
			search: "",
			query: {
				limit: 10,
				page: 1,
				order_by: ``,
				//with: "userProfile,company",
			},
		}),
		computed: {
			...mapState({
				pagination: (state) => state.media.pagination,
				loading: (state) => state.media.loadings,
				session: (state) => state.session.user				
			}),
			...mapGetters({
				l: "lang",
			}),
		},
		methods: {
			...mapActions({
				index: "media/index",
				publishMedia: "media/publish",
				deleteM:"media/delete"
			}),
			searchData() {
				console.log("searchData")
				const query = this.$queryUtils.withoutInvalidValues({ ...this.query, search: this.search,page: 1});
				this.query = query;
				this.index(query);
			},
			changePage(page) {
				console.log("page",page)
				const query = this.$queryUtils.withoutInvalidValues({ ...this.query, page: page});
				this.query = query;
				this.index(query);
				//console.log("this.query",this.query,page)
				//this.query.page = page;
			},
			orderBy(_query) {
				console.log("orderBy",_query)
				const query = this.$queryUtils.withoutInvalidValues({ ...this.query, order_by:_query,page: 1});
				this.query = query;
				this.index(query);
			},
			active(id) {
				if (confirm("Are you sure to activate this Events?")) {
					this.update({id: id, status: "active"});
				}
			},
			deactivate(id) {
				if (confirm("Are you sure to disable this Events?")) {
					this.update({id: id, status: "inactive"});
				}
			},
			isUrlEmbed(string) {
				if (string && string != "") {
					return (
					string.indexOf("youtu") >= 0 ||
					string.indexOf("dai") >= 0 ||
					string.indexOf("vimeo") >= 0 ||
					string.indexOf("coub") >= 0
					);
				}
				return false;
			},
			isUrlFacebook(string) {
				if (string && string != "") {
					return string.indexOf("facebook") >= 0 || string.indexOf("fb.") >= 0;
				}
				return false;
			},
			async publish (mediaId) {
				let response = await this.publishMedia(mediaId);
				if (response) {
					console.log("response",response)
					let index = this.pagination.data.findIndex((item) => item.id == mediaId);
					if (index >= 0) {
						this.pagination.data[index].status = response.status;
					}
				}
			},
			moment,
		},
		created() {
			this.index(this.query);
		},
		watch: {
			search(val) {
				this.query.page = 1;
				if (val.length > 3) {
					this.searchData();
				}
			}
		},
	};
</script>

<style lang="scss">
	.date {
		font-size: 0.8rem !important;
	}
	.video-embed {
		iframe {
			width: 100% !important;
			max-height: 140px !important;
			#player.player {
				max-width: 100% !important;
			}
			img {
				object-fit: cover !important;
				width: 100% !important;
			}
		}
	}
</style>
